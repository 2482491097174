// Initiate/process all htmx element so event listeners are enabled. Used on turbo page navigation to enable event listeners again.
export default function initiateAllHtmxElements(htmx) {
    const htmxElements = document.querySelectorAll('[hx-trigger]');

    htmxElements.forEach((node) => {
        // ignore if htmx has already been initated on the element
        if (!node["htmx-internal-data"]) {
            htmx.process(node);
        }
    })
}