import htmx from 'htmx.org';

/**
 * Add 'x-htmx-get' onto a component as an alternative to htmx-get that respects load events from turbo.
 * @param Alpine
 *
 * Arguments that can be passed to directive
 * url - Required
 */
export const htmxGet = function (Alpine) {
    Alpine.directive('hx-get', (el, { expression }, { evaluate }) => {
        const { url } = evaluate(expression);

        // We have to send a context object as third argument,
        // if not the body is passed instead of the element and only one xhr request can run at a time.
        htmx.ajax('GET', url, { source: el });
    });
};