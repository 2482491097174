import AWN from "awesome-notifications";

let notifier = new AWN({
    animationDuration: 200,
    icons: {
        enabled: false
    },
    labels: { success: '' }
});

window.notifier = notifier;

window.addEventListener('slideout-form-success', () => {
    notifier.success('Form sent successfully');
});