import { Controller } from '@hotwired/stimulus';
import SimpleMde from '../js/vendor/simple-mde/simplemde.min';

export default class extends Controller {
    simpleMde = null;

    connect() {
        window.SimpleMde = SimpleMde;

        this.simpleMde = new SimpleMde({
            element: this.element,
            hideIcons: ['image', 'link', 'quote', 'side-by-side', 'preview'],
        });
    }

    disconnect() {
        if (this.simpleMde) {
            this.simpleMde.toTextArea();
            this.simpleMde = null;
        }
    }
}
