import Cookies from 'js-cookie';

function queueDropdown() {
    function setQueueDropdownOpenStatusOnInit() {
        let cookie = Cookies.get('queueDropdownOpen');

        return cookie === 'true';
    }

    return {
        setQueueDropdownOpenStatusOnInit,
        toggleExtraCallDetails() {
            this.showExtraCallDetails = !this.showExtraCallDetails;

            Cookies.set('queueDropdownOpen', this.showExtraCallDetails);
        },
        showExtraCallDetails: setQueueDropdownOpenStatusOnInit(),
    };
}

window.queueDropdown = queueDropdown;
