function feesTabs(dispatch) {
    return {
        showDropdown: false,
        tabs: [
            {
                label: 'Customer Engagement Visit 1',
                id: 'cev1_fees',
            },
            {
                label: 'Customer Engagement Visit 2',
                id: 'cev2_fees',
            },
            {
                label: 'Remote PDV',
                id: 'remote_pdv_fees',
            },
            {
                label: 'Remote Disconnection',
                id: 'remote_disconnection_visit_fees',
            },
            {
                label: 'Info',
                id: 'info_visit_fees',
            },
            {
                label: 'Revisit Empty',
                id: 'revisit_empty_fees',
            },
            {
                label: 'Post Disconnection',
                id: 'post_disconnection_visit_fees',
            },
            {
                label: 'Debt',
                id: 'debt_visit_fees',
            },
            {
                label: 'Eviction',
                id: 'eviction_visit_fees',
            },
            {
                label: 'Reconnection',
                id: 'reconnection_visit_fees',
            },
            {
                label: 'Meter Read',
                id: 'meter_read_visit_fees',
            },
        ],
        activeTab: null,
        init() {
            this.activeTab = this.tabs[0];

            setTimeout(() => {
                dispatch('fees-tab-changed', {
                    activeTab: this.activeTab,
                });
            }, 0);
        },
        changeTab(tab, dispatch) {
            dispatch('fees-tab-changed', { activeTab: tab });
            this.activeTab = tab;
            this.showDropdown = false;
        },
    };
}

window.feesTabs = feesTabs;
