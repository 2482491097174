import './scss/app.scss';

// start stimulus application
import './bootstrap';

import 'alpine-turbo-drive-adapter';
import Alpine from 'alpinejs';
import trap from '@alpinejs/focus';
import htmx from 'htmx.org';
import { htmxDispatch } from '@mashbo/js-template-bundle/components/htmx';
import { filters } from '@mashbo/js-template-bundle/components/filters';
import { overlay, overlayButton, overlayFormResponseError } from '@mashbo/js-template-bundle/components/overlays';
import { disableFormsOnSubmit } from '@mashbo/js-template-bundle/helpers/disable-button';
import { multiSelect } from '@mashbo/js-template-bundle/directives/multi-select';
import { datepickerDirective as datepicker } from '@mashbo/js-template-bundle/directives/datepicker';
import { slideoutForm } from '@mashbo/js-template-bundle/directives/slideout-form';
import { disableOnSuccess } from '@mashbo/js-template-bundle/directives/slideout-button';
import { confirmationModal } from '@mashbo/js-template-bundle/components/confirmation-modal';

import { dropoutMenu } from "./js/components/js-dropout-menu";
import { htmxGet } from './js/directives/htmx-get';

window.Alpine = Alpine;
window.htmx = htmx;
window.htmxDispatch = htmxDispatch;
window.dropoutMenu = dropoutMenu;
window.overlay = overlay;
window.overlayButton = overlayButton;
window.tableWithFilters = filters;
window.responseError = overlayFormResponseError;
window.confirmationModal = confirmationModal;

import './js/components/fees-tabs';
import './js/components/queue-dropdown';
import './js/components/refreshable-loader';
import './js/components/toast';
import "./js/helpers/turbo-helper";

// Any Alpine components need to come before Alpine starts.
Alpine.plugin(htmxGet);
Alpine.plugin(datepicker);
Alpine.plugin(disableOnSuccess);
Alpine.plugin(slideoutForm);
Alpine.plugin(multiSelect);
Alpine.plugin(trap);

Alpine.start();

disableFormsOnSubmit();

document.addEventListener("DOMContentLoaded", function() {
    // Enables css transitions on dom load so we dont get transitions running on page load.
    document.querySelector('.css-transitions-only-after-page-load').classList.remove('css-transitions-only-after-page-load');
});