import isObjectEmpty from "../utils/is-object-empty";
import initiateAllHtmxElements from "./initiate-htmx-elements";
import htmx from "htmx.org";
import { htmxSwapOn422 } from "@mashbo/js-template-bundle/src/components/htmx";

class TurboHelper {
    constructor() {
        window.addEventListener('turbo:load', (event) => {
            this.initiateHtmxElements(event);
            this.clearHtmxGlobals();
            htmxSwapOn422();
        });
    }

    initiateHtmxElements(event) {
        const timing = event.detail.timing;
        const initialPageLoad = isObjectEmpty(timing);

        if (!initialPageLoad) {
            initiateAllHtmxElements(htmx);
        }
    }

    clearHtmxGlobals() {
        // Stops slide out reloading the same content.
        window.previousHtmxUrl = '';
    }
}

export default new TurboHelper();